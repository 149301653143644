

.accordion-trigger h2{
opacity: 1;
transition: 0.3s ease;
}

.accordion-trigger h2:hover{
opacity: .75;
transition: 0.1s ease;
}

.accordion-title h2{
opacity: 1;
transition: 0.3s ease;
}

.accordion-title h2:hover{
opacity: .75;
transition: 0.1s ease;
}

.accordion-content{
   display: none;
}

.accordion-content.open{
}



.no-cursor{
  cursor: default;
}


.accordion-content li{
    width: 50%;
}

.accordion-content h2 a{
    display: inline;
    border-bottom: 2px currentColor solid;
    padding-bottom: calc(2px + .05em);
    transition: 0s;
}

.accordion-content h2 a:hover{
    border-bottom: 0px currentColor solid;
    padding-bottom: calc(2px + .05em);
    transition: 0s;
}


.accordion-content p:nth-child(1){

}


.aside-download  {
  position: relative;

}

.aside-download p {
  position: absolute;
  margin-left: 0%;
  width: 10vw;

}
.cont{
    display: flex;
    justify-content: space-between;
}

.accordion-content img{
  width: 100%;
}

.accordion-image img{
  width: 200%;
  margin-left: 0%

}

.bottom-50{
  margin-bottom: 25px;
}

.top-5{
  margin-top: 5px;
}

hr {
  width: 100%;
  margin-top: -4px;
	border-top: 1px solid #000;
  opacity: .25;
}



.accordion-content h3 p{
    position: relative;
    top: 5px;
    width: 70%;
    font-size: 12px;
    line-height: 1.25em;
    font-weight: 500;
    letter-spacing: -0.0025em;
    word-spacing: -0.0025em;
    color: #888888;
}










.accordion-content-open li{
    width: 50%;
}

.accordion-content-open h2 a{
    display: inline;
    border-bottom: 2px currentColor solid;
    padding-bottom: calc(2px + .05em);
    transition: 0s;
}

.accordion-content-open h2 a:hover{
    border-bottom: 0px currentColor solid;
    padding-bottom: calc(2px + .05em);
    transition: 0s;
}

.accordion-content-open img{
  width: 100%;
}

.aside-download-open  {
  position: relative;
}

.aside-download-open p {
  position: absolute;
  margin-left: 0%;
  width: 10vw;

}

.accordion-content-open h3 p{
    position: relative;
    top: 5px;
    width: 70%;
    font-size: 12px;
    line-height: 1.25em;
    font-weight: 500;
    letter-spacing: -0.0025em;
    word-spacing: -0.0025em;
    color: #888888;
}

@media screen and (min-width: 1680px) and (max-width: 10000px) {

.accordion-content h3 p{
    font-size: 16px;
}

.accordion-content-open h3 p{
    font-size: 16px;
}
    }

@media screen and (min-width: 0px) and (max-width: 599px) {
  .accordion-content h3 p{
      font-size: 11px;
  }

  .accordion-content-open h3 p{
      font-size: 11px;
  }

  .accordion-content-open li{
      width: 70%;
      margin-left: 30%;
  }
  .aside-download-open  {
    position: absolute;
    left: -30%;
  }
  .accordion-content li{
      width: 70%;
      margin-left: 30%;
  }
  .aside-download  {
    position: absolute;
    left: -30%;
  }
  .accordion-image img{
    width: 100%;
    margin-left: 0%

  }

      }
