
html{
    font-family: 'HelveticaN', "Helvetica", "Arial", sans-serif;
}

h1, h2, h3, h4, h5, h6, a, p, blockquote {
    border: 0;
    margin: 0;
    padding: 0;
    color: #000;
    font-weight : normal;
    -webkit-font-smoothing: antialiased;
	  -moz-osx-font-smoothing: grayscale;
}

b, strong {
    font-weight: 800;
    letter-spacing: -0.01em;
}

a {
    text-decoration: none;
    color: currentColor;
    cursor: pointer;
    clear: both;
    border-bottom: 1px currentColor solid;
    padding-bottom: .05em;
    opacity: 1;
    transition: opacity .1s ease;
    padding-bottom: 0px;
}

a:hover{
    opacity: .75;
    border-bottom: 0px currentColor solid;
    padding-bottom: 1px;
}

.no-underline{
    opacity: 1;
    border-bottom: 0px currentColor solid;
    padding-bottom: 0em;
    color: currentColor;
    transition: opacity .1s ease;
}

.no-underline:hover{
    opacity: .75;
    border-bottom: 1px currentColor solid;
}

.no-underline-h2{
    opacity: 1;
    border-bottom: 0px currentColor solid;
    padding-bottom: 0em;
    color: currentColor;
    transition: opacity .1s ease;
}
.no-underline-h2:hover{
    padding-bottom: 0em;
}

.image-link{
    filter: grayscale(100);
    opacity: 1;
    border-bottom: 0px currentColor solid;
}

.image-link:hover{
    filter: grayscale(0);
    opacity: .75;
    border-bottom: 0px currentColor solid;
}

.footer-top a{
    opacity: 1;
    border-bottom: 0px currentColor solid;
    padding-bottom: 0em;
    color: currentColor;
}

.footer-top a:hover{
    opacity: 1;
    color: #222222;
}

small, sub, sup {
    font-size: 50%;
}

sub, sup {
    line-height: 0;
    position: relative;
    vertical-align: baseline
}

sub {
    bottom: -.25em
}

sup {
    top: -.8em;
    font-size: 0.5em;
}

h1 {
    font-weight: 500;
    font-size: 64px;
    line-height: 1.25em;
    letter-spacing: -.025em;
    word-spacing: 0.025em;
}

h1 svg{
    display: inline-block;
    margin-top: .1em;
    position: absolute;
    padding-right: .5em;
    margin-left: -1.65em;
    width: 1.875em;
}

h2 {
    font-size: 32px;
    line-height: 1.25em;
    font-weight: 500;
    letter-spacing: -0.025em;
    word-spacing: -0.06em;
}

h2 a{
    opacity: 1;
    border-bottom: 0px currentColor solid;
    padding-bottom: 0em;
    display: block;
    color: currentColor;
    transition: .1s cubic-bezier(0.4, 0.1, 0.2, 0.9);
}

h2 a:hover{
    opacity: 1;
}

h2 svg{
    display: inline-block;
    float: right;
    margin-top: .15em;
    padding-right: .1em;
    width: 1em;
}

h2 span img{
    display: inline-block;
    float: right;
    margin-top: .15em;
    padding-right: .1em;
    width: 1em;
    transform: translate(0em, 0em) rotate(0deg);
    transition: .4s cubic-bezier(0.6, 0.1, 0.1, 1);
}

h2 span img.open{
    transform: translate(0em, .05em) rotate(45deg);
    transition: .4s cubic-bezier(0.6, 0.1, 0.1, 1);
}

h3 {
    font-size: 12px;
    line-height: 1.25em;
    font-weight: 500;
    letter-spacing: -0.0025em;
    word-spacing: -0.0025em;
    color: #888888;
}

h3 svg{
  position: relative;
    display: inline;
    padding-right: .5em;
    width: 1.3em;
}

p {
    font-size: 16px;

    line-height: 1.25em;
    font-weight: 500;
    letter-spacing: -0.0075em;
    word-spacing: -0.0075em;
    margin-bottom: 1.25em;
}



p svg{
  position: relative;
    display: inline;
    padding-right: .5em;
    width: 1.3em;
}

blockquote p{
    font-size: 24px;
    line-height: 1.25em;
    font-weight: 500;
    letter-spacing: -0.0075em;
    word-spacing: -0.0075em;
}




#arrow-hover:hover svg{
    transform: translateX(0px) scaleX(1);
    transition: .4s ease;
}

#arrow-hover:hover #h2-arrow-head{
    transform: translateX(10em) scaleX(1);
    transition: .2s ease;
}

#arrow-hover:hover #h2-arrow-body{
    transform: translateX(0em) scaleX(1);
    transition: .2s ease;
}

#h2-arrow-head{
    transform: translateX(0em) scaleX(1);
    transition: .2s ease;
}

#h2-arrow-body{
    transform: translateX(0em) scaleX(1);
    transition: .2s ease;
}

@media screen and (min-width: 1680px) and (max-width: 10000px) {
h1 {
    font-size: 80px;
}

h2 {
    font-size: 40px;
}

h3 {
    font-size: 16px;
}

p {
    font-size: 20px;
}

    }


@media screen and (min-width: 600px) and (max-width: 1300px) {
h1 {
    font-size: 52px;
}

h2 {
    font-size: 28px;
}

p {
    font-size: 16px;
}
    }

@media screen and (min-width: 361px) and (max-width: 599px) {
h1 {
    font-size: 32px;
}

h2 {
    font-size: 24px;
}

p {
    font-size: 14px;
}


h3 {
    font-size: 10px;
}
    }
    @media screen and (min-width: 0px) and (max-width: 360px) {
    h1 {
        font-size: 24px;
    }

    h2 {
        font-size: 18px;
    }

    p {
        font-size: 14px;
    }


    h3 {
        font-size: 10px;
    }
        }
